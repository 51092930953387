<template>
  <v-container>
    <h2 class="header header-span mb-8">Reset your password</h2>
    <v-row class="d-flex justify-center">
      <v-col>
        <v-form
            @submit.prevent="submit"
            ref="form"
            v-model="valid"
        >
          <div class="prompt mb-lg-5">
            Enter your new password.
          </div>
          <TextField
            type="password"
            label="Password"
            v-model="model.password"
            class="mb-lg-5 mb-sm-2"
            :rules="rules.passwd"
            />
          <TextField
            type="password"
            label="Confirm Password"
            v-model="model.passwordCon"
            class="mb-lg-5 mb-sm-2"
            :rules="[ rules.passwdConf ]"
            />
          <AlertError :error="errorMsg" />
          <v-btn
              block
              class="btn btn--accent mb-5"
              color="secondary"
              type="submit"
              :loading="api.loading"
          >
            <span slot="loader">
              <v-progress-circular
                  indeterminate
                  color="primary"
              />
            </span>
            <span class="btn__text btn__text--white">
                Reset password
              </span>
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
    <v-snackbar
        color="success"
        elevation="24"
        min-width="none"
        height="50"
        v-model="notify.visible">
      <div class="snack-text">
        <v-icon large class="pr-3" v-if="notify.icon">{{  notify.icon }}</v-icon>
        {{ notify.text }}
      </div>
    </v-snackbar>
  </v-container>
</template>

<script>

import TextField from '@/components/TextField.vue'
import Http from '@/api/Http';
import AlertError from '@/components/AlertError';

export default {
  name: 'AuthReset',
  components: { TextField, AlertError },
  data() {
    return {
      api: Http.use('data'),
      valid: true,
      errorMsg: '',
      notify: {
        visible: false,
        icon: null,
        text: null,
        color: null
      },
      model: {
        password: '',
        passwordCon: ''
      },
      rules: {
        passwd: [
          v => !!v || 'Password is required',
          v => /^.{6,64}$/.test(v) || 'Password must be at least six characters long and no longer than 64.'
        ],
        passwdConf: value => {
          return (this.model.password === value) || 'Passwords do not match'
        }
      }
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.errorMsg = '';
        this.api.post('/auth/reset', {
          passwd: this.model.password,
          token: this.$route.params.token
        })
            .then(() => {
              this.$toast('Password reset succeeded');
              this.$router.push('/login');
            })
            .catch(err => {
              this.errorMsg = err.response.data.error;
            });
      } else {
        this.$toast('There is a problem with the password you entered.', {
          color: 'error',
          icon: 'mdi-alert-circle-outline'
        });
      }
    }
  }
}
</script>

<style scoped>
.header {
  color: var(--v-secondary-base);
  text-align: center;
  line-height: 100%;
}
.prompt {
  text-align: center;
  line-height: 150%;
  font-size: 1.5rem;
}
</style>